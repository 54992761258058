// import getIsMobile from 'ismobilejs';

// const isCypressMobile = window.Cypress && window.innerWidth < 650;
// const isMobile = getIsMobile(window.navigator).any || isCypressMobile;

// if (isMobile) {
//     import('./mobile/bootstrap').then(({ initialize }) => {
//         initialize();
//     });
// } else {
import('./desktop/bootstrap').then(({ initialize }) => {
    initialize();
});
// }
